<template>
  <aside
    class="d-flex flex-column sidebar shadow"
    :class="getSidebarState ? 'active' : 'inactive'"
   
  >
    <ul  v-touch:swipe="changeSidebarState">
        <li class="homeb inactive" :class="{active: this.pathUrl === 'Home'}">
        <router-link
          title="Início"
          to="/"
         
        >
         Início
        </router-link>
      </li>

      <li class="user inactive" :class="{active: this.pathUrl === 'Newuser'}">
        <router-link
          title="Usuários"
          to="/newuser"
         
        >
         Usuários
        </router-link>
      </li>
      

       <li class="client inactive" :class="{active: this.pathUrl === 'Clientes'}">
        <router-link
          title="Clientes"
          to="/customer"
        >
         Empresas
        </router-link>
      </li>

      <li class="bill inactive" :class="{active: this.pathUrl === 'Billing'}">
        <router-link
          title="Billing"
          to="/billing"
         
        >
         Billing
        </router-link>
      </li>

       <li class="contract inactive" :class="{active: this.pathUrl === 'Classificador'}">
        <router-link
          title="Classificador"
          to="/classificador"
        >
         Classificador
        </router-link>
      </li> 
        <li class="entities inactive" :class="{active: this.pathUrl === 'Entities'}">
          <router-link
            title="Entities"
            to="/entities"
          >
          Entities
          </router-link>
        </li> 
             <li class="matriculasprocess inactive" :class="{active: this.pathUrl === 'Matrícula Process Log'}">
        <router-link
          title="Matrícula Process Log"
          to="/processlog"
        >
         Process Log
        </router-link>
      </li>  
      <li class="monitor_ inactive" :class="{active: this.pathUrl === `Monitor Revisor`}">
        <router-link
          title="Monitor Revisor"
          to="/monitor-revisor"
        >
         Revisor
        </router-link>
      </li>  

      <li class="annotator_ inactive" :class="{active: this.pathUrl === `Annotator Verifier`}">
        <router-link
          title="Annotator Verifier"
          to="/verifier/areas"
        >
         Annotator Areas
        </router-link>
      </li>
        <li class="annotator1_ inactive" :class="{active: this.pathUrl === `Annotator Comprador`}">
        <router-link
          title="Annotator Comprador"
          to="/verifier/comprador"
        >
         Annotator Comprador
        </router-link>
      </li>
      <li class="annotator2_ inactive" :class="{active: this.pathUrl === `Annotator`}">
        <router-link
          title="Annotator"
          to="/annotator"
        >
         Annotator
        </router-link>
      </li>

      <li class="annotator3_ inactive" :class="{active: this.pathUrl === `Annotatorparagraphs`}">
        <router-link
          title="Annotator paragraphs"
          to="/annotatorparagraphs"
        >
         Annotator Paragraphs
        </router-link>
      </li>

  


      <li class="annotator4_ inactive" :class="{active: this.pathUrl === `Annotator Fix`}">
        <router-link
          title="Annotator Fix"
          to="/revannotator"
        >
         Annotator Fix
        </router-link>
      </li> 
      <li class="okr2_ inactive" :class="{active: this.pathUrl === `OKR`}">
        <router-link
          title="OKR"
          to="/okrs"
        >
        OKR's
        </router-link>
      </li>   
       <li class="okr_ inactive" :class="{active: this.pathUrl === `Rating`}">
        <router-link
          title="Rating"
          to="/rating"
        >
        Notas
        </router-link>
      </li>            
    <li class="logout inactive" @click="$store.dispatch('logout')">
        
         Logout
        
      </li>
      <!-- <li class="analise inactive" :class="{active: this.pathUrl === 'Análise'}"> <router-link
          title="Análise"
          to="/"
         
        >
         Análise
        </router-link></li> -->
    </ul>
  </aside>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";

export default {
  props: {},
  name: "Sidebar",
  data() {
    return {
      pathUrl: "",
    };
  },
  computed: {
    ...mapGetters(["getSidebarState"]),
    sidebarState: {
          get:function() {
            return this.getSidebarState
          },
          set: function() {
            this.$store.dispatch('changeSiderbarstate',this.sidebarState)
          }
      }
  },
  created() {
    this.pathURL = this.$router.history.current.name;
    
  },
  watch: {
    $route(to) {
      this.pathUrl = to.name;
     
    },
  },
  methods: {
    changeSidebarState() {
     
          if(this.sidebarstate == true) this.sidebarState = false
          else this.sidebarState = true
        }
  },
};
</script>
<style src="./sidebar.less" lang="less" />
