<template>
  <div class="main">
    <Header />
    <Sidebar />
     <router-view name="application" />  
  </div>
</template>

<script>
import { MainMixin } from "./Main.mixin.js";
import  Header  from '../../components/ui/header/header'
import  Sidebar  from '../../components/ui/sidebar/sidebar'
export default {
  name: "Main",
  components: {
    Header,
    Sidebar
  },
  mixins: [MainMixin],
};
</script>
<style src="./Main.less" lang="less" />
