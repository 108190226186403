<template>
   <header class="d-flex flex-column justify-content-center shadow-sm">
      <div class="row">
      <div class="col-4 logoLeft">
        <div v-bind:class="{ open: isOpen == true }" id="nav-icon4" @click="changeSidebarState()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="col-4 logoCenter align-self-center">
           <img   src="@/assets/images/new-logo-beaver.png" />
      </div>
      <div class="col-4 align-self-center">
        
        <div class="boxRate">
          <div class="rate"><span>Avaliações:</span>{{countrating}}</div>
          <div class="rate"><span>Nota média:</span>{{rating}}</div>
        </div>
      </div>
      </div>
    </header>
</template>

<script type="text/javascript">
import { getRate } from '../../../services/logs.services'
import { mapGetters } from "vuex";
export default {
    props:{
        
    },
    name: 'Header',
    data(){
        return {
            rating:0,
            countrating:0,
            totalRating:0,
            isOpen: false,
        }
    },

    watch:{
        $route (to, from){
            this.isOpen = false;
        }
    },

    computed:{
      ...mapGetters(["getSidebarState"]),
      sidebarState: {
          get:function() {
            return this.getSidebarState
          },
          set: function() {
            this.$store.dispatch('changeSiderbarstate',this.sidebarState)
          }
      }
    },
    mounted() {
      this.getRating()
    },
    methods:{

        async getRating() {
          try {
             const {data} = await getRate()
             console.log(data)
             this.countrating = data.data.countrating
             this.totalRatings = data.data.ratings.reduce((ac,val)=>{
                 return ac + val
             })
             this.rating = `${(this.totalRatings / this.countrating).toFixed(2)}`
          }
          catch(e) {
            console.log(e)
          }

        },

        changeSidebarState() {
          if(this.sidebarstate == true) this.sidebarState = false
          else this.sidebarState = true
          this.isOpen = !this.isOpen;
        }
    }
   
}
</script>
<style src="./header.less" lang="less" />