export const MainMixin = {
    data() {
        return {

        };
    },
    mounted() {


    },
    watch: {

    },
    computed: {


    },
    methods: {


    }

};